/* eslint-disable react/sort-comp */
/* eslint-disable no-template-curly-in-string */
import React, {
  createRef,
} from 'react';
// import ReactDOM from 'react-dom';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import OSM from 'ol/source/OSM';
import { Feature, Map as OLMap, View } from 'ol';
import { Attribution, defaults as defaultControls } from 'ol/control';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
// import GeoJSONFormat from 'ol/format/GeoJSON';

import '../../styles/map/index.css';
import XYZ from 'ol/source/XYZ';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';
import RegularShape from 'ol/style/RegularShape';
// import { Snap } from 'ol/interaction';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Overlay from 'ol/Overlay';
// import * as proj from 'ol/proj';
import themes from '../../styles/themes';
import projects from '../../projects';
import { baseURLs } from '../../tileLayers/url';
import MapDialog from '../mapDialog';

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.layers = props.layers;
    this.attributes = props.attributes;
    this.isLoading = props.isLoading;
    this.setSelectedPixel = props.setSelectedPixel;
    this.mapRef = createRef();
    this.selectedState = props.selectedState;
    this.setBaseAttributes = props.setBaseAttributes;
    this.setAttributes = props.setAttributes;
    this.referenceAttributes = props.referenceAttributes;
    this.setReferenceAttributes = props.setReferenceAttributes;
    this.pinLayer = undefined;
    this.setClearMarkIsShowing = props.setClearMarkIsShowing;
    this.linkObj = props.linkObj;
    this.baseAttributes = props.baseAttributes;
    this.popup = undefined;
    this.popupref = createRef();
    this.currCoords = [0, 0];
    this.sp = undefined;
    this.savedPinLayer = undefined;
    this.pinLoader = props.pinLoader;
    this.usePins = props.usePins;
    this.useDialog = props.useDialog;
  }

  async componentDidMount() {
    if (this.selectedState) {
      const project = projects[this.selectedState.abbreviation];
      if (project) {
        // Need to build layers
        const layers = [];
        const baseAttributes = {};
        const attributes = {};
        const referenceAttributes = {};

        project.baseLayers.forEach((l) => {
          if (l.internalName === 'oSM') {
            const newTileLayer = new TileLayer({
              source: new OSM({
              }),
            });

            newTileLayer.setProperties({
              iName: l.internalName,
            });

            if (this.linkObj.bL) {
              newTileLayer.setVisible(l.internalName === this.linkObj.bL);
            } else {
              newTileLayer.setVisible(l.selected);
            }

            layers.push(newTileLayer);

            baseAttributes.oSM = {
              iName: l.internalName,
              name: l.name,
              selected: newTileLayer.getVisible(),
              toolsVisible: l.toolsVisible,
            };
          }

          if (l.internalName === 'baseSatellite') {
            const newXYZLayer = new TileLayer({
              source: new XYZ({
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                attributions: ['Esri, Maxar, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, GIS User Community'],
              }),
            });

            newXYZLayer.setProperties({
              iName: l.internalName,
            });

            if (this.linkObj.bL) {
              newXYZLayer.setVisible(l.internalName === this.linkObj.bL);
            } else {
              newXYZLayer.setVisible(l.selected);
            }

            layers.push(newXYZLayer);

            baseAttributes.baseSatellite = {
              iName: l.internalName,
              name: l.name,
              selected: newXYZLayer.getVisible(),
              toolsVisible: l.toolsVisible,
            };
          }

          if (l.internalName === 'baseFootprints') {
            const newXYZLayer = new TileLayer({
              source: new XYZ({
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                attributions: ['Esri, HERE, Garmin, Intermap, increment P Corp., GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), (c) OpenStreetMap contributors, GIS User Community'],
              }),
            });

            newXYZLayer.setProperties({
              iName: l.internalName,
            });

            if (this.linkObj.bL) {
              newXYZLayer.setVisible(l.internalName === this.linkObj.bL);
            } else {
              newXYZLayer.setVisible(l.selected);
            }

            layers.push(newXYZLayer);

            baseAttributes.baseFootprints = {
              iName: l.internalName,
              name: l.name,
              selected: newXYZLayer.getVisible(),
              toolsVisible: l.toolsVisible,
            };
          }
        });

        project.datasets.forEach((l) => {
          const newTileLayer = new TileLayer({
            source: new TileWMS({
              // url: `${baseURL}geoserver/pyrologix/wms`,
              urls: baseURLs.map((url) => `${url}geoserver/pyrologix/wms`),
              serverType: 'geoserver',
              params: {
                ...l.wmsParams,
                STYLES: '',
                TILED: true,
              },
              transition: -1,
            }),

          });

          newTileLayer.setProperties({
            iName: l.internalName,
          });

          if (this.linkObj.hL) {
            newTileLayer.setVisible(l.internalName === this.linkObj.hL);
            newTileLayer.setOpacity(this.linkObj.hO);
          } else {
            newTileLayer.setVisible(l.selected);
            newTileLayer.setOpacity(project.defaultHazardOpacity);
          }

          layers.push(newTileLayer);

          attributes[l.internalName] = {
            iName: l.internalName,
            name: l.name,
            selected: newTileLayer.getVisible(),
            toolsVisible: newTileLayer.getVisible(),
          };
        });

        project.referenceLayers.forEach((l) => {
          let newTileLayer;
          if (l.externalLayer) {
            newTileLayer = l.externalLayer;
          } else {
            newTileLayer = new TileLayer({
              source: new TileWMS({
                // url: `${baseURL}geoserver/pyrologix/wms`,

                urls: baseURLs.map((url) => `${url}geoserver/pyrologix/wms`),
                serverType: 'geoserver',
                params: {
                  ...l.wmsParams,
                  TILED: true,
                },
                transition: -1,
              }),
            });
          }

          newTileLayer.setProperties({
            iName: l.internalName,
            nonInteractive: l.nonInteractive,
          });

          // Toggle default visibility
          if (this.linkObj.rLS) {
            const linkRefLayers = this.linkObj.rLS;
            newTileLayer.setVisible(linkRefLayers.includes(l.internalName));
            newTileLayer.setOpacity(this.linkObj.rLO);
          } else {
            newTileLayer.setVisible(l.selected);
          }

          layers.push(newTileLayer);

          // This needs to change a little
          referenceAttributes[l.internalName] = {
            iName: l.internalName,
            name: l.name,
            selected: newTileLayer.getVisible(),
            toolsVisible: l.toolsVisible,
          };
        });

        const view = new View({
          center: this.linkObj.center || project.center,
          zoom: this.linkObj.zoomLevel || project.zoom,
          maxZoom: project.maxZoom || 14,
        });

        const attribution = new Attribution({
          collapsible: false,
        });

        const newMap = new OLMap({
          target: this.mapRef.current,
          pixelRatio: 1, // Important or else strange tile sizes may be requested.
          controls: defaultControls({ attribution: false, zoom: true }).extend([attribution]),
          layers,
          view,
        });

        this.map = newMap;

        // this.map.on('moveend', () => {
        //   const zL = this.map.getView().getZoom();
        //   console.log('zoom level:', zL)
        // })

        this.popup = new Overlay({ element: this.popupref.current, offset: [10, 10] });

        /* IF YOU WANT GRAB/GRABBING CURSOR ON COMMENT THIS BLOCK */
        // this.map.getViewport().style.cursor = 'grab';
        // this.map.on('pointerdrag', () => {
        //   this.map.getViewport().style.cursor = 'grabbing';
        // });

        // this.map.on('pointerup', () => {
        //   this.map.getViewport().style.cursor = 'grab';
        // });

        this.setBaseAttributes(baseAttributes);
        this.setAttributes(attributes);
        this.setReferenceAttributes(referenceAttributes);

        this.project = project;

        this.map.on('singleclick', async (e) => {
          let coord = e.coordinate;
          let featAtPixel = [];
          if (this.savedPinLayer) {
            featAtPixel = await this.savedPinLayer.getFeatures(e.pixel);
          }
          // const featAtPixel = await this.savedPinLayer.getFeatures(e.pixel);
          if (featAtPixel.length) {
            coord = featAtPixel[0].getGeometry().getCoordinates();
            if (this.currCoords[0] === coord[0] && this.currCoords[1] === coord[1]) {
              return;
            }
          }

          // const visibleDatasets = this.getVisibleDatasets(this.baseAttributes);
          const visibleDatasets = this.getVisibleDatasets(baseAttributes);
          // Need to figure out snap coordinate
          // https://stackoverflow.com/questions/43813089/get-coordinates-from-snap-in-openlayers
          const dataForCoordinate = await this.getDataForCoordinate(coord, visibleDatasets);
          this.updateSelectedPixel(coord, dataForCoordinate);
        });

        if (this.linkObj.sPC) {
          const coord = this.linkObj.sPC;
          // this.baseAttributes unavailable at this time
          const visibleDatasets = this.getVisibleDatasets(baseAttributes);
          const dataForCoordinate = await this.getDataForCoordinate(coord, visibleDatasets);
          this.updateSelectedPixel(coord, dataForCoordinate);
        }

        this.loadPins();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.baseAttributes !== prevProps.baseAttributes) {
      this.baseAttributes = prevProps.baseAttributes;
    }
  }

  getVisibleDatasets(baseAttributes) {
    const baseLayers = Object.keys(baseAttributes);
    const datasets = this.getLayers()
      .filter((l) => {
        const name = l.getProperties().iName;
        return !baseLayers.includes(name);
      })
      .filter((l) => l.getProperties().iName !== 'pin')
      .filter((l) => l.getProperties().iName !== 'saved-pins')
      .filter((l) => l.getProperties().nonInteractive !== true);
    return datasets;
  }

  async getDataForCoordinate(coord, layers) {
    // const viewResolution = this.map.getView().getResolution();
    const resps = await Promise.all(layers.map((d) => {
      const { iName } = d.getProperties();
      const url = d.getSource().getFeatureInfoUrl(
        coord,
        // This will fix issue with pixel coords i,j being
        // different due to change bounding box
        // which in turn can lead to miss identification of underlying
        // pixel information
        // See https://github.com/pyrologix/fire-viz/issues/41
        this.map.getView().getMinResolution(),
        // viewResolution,
        'EPSG:3857',
        { INFO_FORMAT: 'application/json' },
      );
      if (url) {
        const resp = fetch(url)
          .then((response) => response.json())
          .then((json) => {
            const pixel = json?.features[0]?.properties?.GRAY_INDEX;
            if (pixel !== undefined && Number.isFinite(pixel)) {
              return {
                iName,
                value: pixel,
              };
            }
            return {
              iName,
              value: false,
            };
          })
          .catch(() => ({
            iName,
            value: false,
          }));
        return resp;
      }
      return false;
    }));

    return resps;
  }

  getLayers(asObject = false) {
    // eslint-disable-next-line max-len
    const layers = this.map.getLayers().getArray();// .filter((l) => l.getProperties().iName !== 'oSM');

    if (asObject) {
      const layersAsObject = layers.reduce((obj, l) => ({
        ...obj,
        [l.getProperties().iName]: l,
      }), {});
      return layersAsObject;
    }

    return layers;
  }

  updateSelectedPixel(coord, layers) {
    /**
     * Update selectedPixel and sp
     * Drop pin. I don't know if this should happen here
     */
    const newSelectedPixel = {
      layers: [
        ...layers,
      ],
    };

    // TEMPORARY: Building an object called sp that is provided
    // to map dialog. (rendered inside of that component)
    const sp = {};
    newSelectedPixel.layers.forEach((layer) => {
      sp[layer.iName] = layer.value;
    });
    this.sp = sp;

    // Drop pin on the map
    this.dropPin(coord);
    this.currCoords = coord;
    this.setSelectedPixel(newSelectedPixel);
  }

  async loadPins() {
    if (!this.usePins) return;
    const pins = await this.pinLoader();
    // const pins = [];
    const features = [];

    const fill = new Fill({ color: 'rgba(121, 202, 245, 0.8)' });
    const stroke = new Stroke({
      color: 'rgba(39, 174, 245, 0.8)',
      width: 1,
    });

    pins.forEach((p) => {
      const pt = new Point([p.x, p.y]);
      const feat = new Feature({ geometry: pt });
      const circle = new Circle({ fill, stroke, radius: 10 });
      const style = new Style({ image: circle, fill, stroke });
      feat.setStyle(style);
      features.push(feat);
    });

    const vecSrc = new VectorSource({ features });
    const vecLyr = new VectorLayer({ source: vecSrc });

    vecLyr.setProperties({ iName: 'saved-pins' });

    if (this.savedPinLayer) {
      this.map.removeLayer(this.savedPinLayer);
    }

    this.savedPinLayer = vecLyr;
    this.map.addLayer(vecLyr);

    // const snap = new Snap({ source: vecSrc });
    // this.map.addInteraction(snap);
  }

  removePinLayer() {
    this.map.removeLayer(this.pinLayer);
  }

  dropPin(coords) {
    // console.log('drop pin: ', coords);
    // const pinCoords = e.coordinate;
    // console.log('pinCoords', pinCoords)
    const point = new Point(coords);

    const newFeature = new Feature({
      geometry: point,
    });

    const stroke = new Stroke({ color: themes.mainDark, width: 2 });

    newFeature.setStyle(
      new Style({
        image: new RegularShape({
          // fill: fill,
          stroke,
          points: 4,
          radius: 5,
          radius2: 0,
          // angle: Math.PI / 4,
          angle: 0,
        }),
      }),
    );

    const newVectorSource = new VectorSource({
      features: [
        newFeature,
      ],
    });

    const newVectorLayer = new VectorLayer({
      source: newVectorSource,
    });

    newVectorLayer.setProperties({
      iName: 'pin',
    });

    if (this.pinLayer) {
      this.removePinLayer();
    }

    this.pinLayer = newVectorLayer;
    this.setClearMarkIsShowing(true);
    this.map.addLayer(newVectorLayer);
    this.popup.setPosition(coords);

    if (this.useDialog) {
      this.map.addOverlay(this.popup);
    }
  }

  findLayerByIName(iName) {
    const layers = this.map.getLayers().getArray();
    const layer = layers.find((l) => l.getProperties().iName === iName);
    return layer;
  }

  toggleVisibility(iName) {
    const layer = this.findLayerByIName(iName);
    const visible = layer.getVisible();
    layer.setVisible(!visible);
  }

  removePopup() {
    this.map.removeOverlay(this.popup);
  }

  resetCoords() {
    this.currCoords = [0, 0];
  }

  async toCoords(x, y) {
    const visibleDatasets = this.getVisibleDatasets(this.baseAttributes);
    const coord = [x, y];
    const dataForCoord = await this.getDataForCoordinate(coord, visibleDatasets);
    this.updateSelectedPixel(coord, dataForCoord);
    this.map.getView().animate({
      center: coord,
      zoom: 12,
    });
  }

  // async fetchCurrentFirePerimeters() {
  //   const url = 'https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/Current_WildlandFire_Perimeters/FeatureServer/0/query?where=1%3D1&outFields=poly_IncidentName&geometry=&geometryType=esriGeometryEnvelope&inSR=3857&spatialRel=esriSpatialRelIntersects&outSR=3857&f=geojson'
  //   const resp = await fetch(url, {
  //     method: 'GET',
  //   }).then((response) => response.json())
  //     .catch((error) => console.error('Error', error))
  //   return resp
  // }

  render() {
    if (!this.props) {
      return null;
    }
    return (
      // <div id="map" className="map" ref={this.mapRef} />
      <>
        <div id="map" className="map" ref={this.mapRef} />
        <MapDialog
          _ref={this.popupref}
          coords={this.currCoords}
          sp={this.sp}
          onClose={() => {
            this.removePinLayer();
            this.removePopup();
            this.resetCoords();
          }}
          onSavePoint={() => {
            this.loadPins();
          }}
          onUnsavePoint={() => {
            this.loadPins();
          }}
        />
      </>

    );
  }
}
