import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import time from '../../helpers/time';
import themes from '../../styles/themes';

const Container = styled.div`
  font-size: 10pt;
  padding: 0.25rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: ${themes.borderRadius};
  margin-top: 0.5rem;
  cursor: pointer;
  :hover {
    background-color: #f7f7f7;
  }
`;

const CommentPreview = styled.div`
  font-size: 9pt;
  opacity: 0.7;
  font-style: italic;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Footer = styled.div`
    display: flex;
    font-size: 8pt;
    margin-top: 0.25rem;
    justify-content: space-between;
    align-items: center;
`;

export default function Alert(props) {
  const {
    data, onToggleRead, onClick,
  } = props;
  const {
    // eslint-disable-next-line camelcase
    content, id, read, created_at,
  } = data;

  return (
    <Container onClick={() => onClick?.(data)}>
      {content.message}

      {content.type === 'new_comment' && (
        <>
          {content.body && (
          <CommentPreview>{content.body}</CommentPreview>
          )}
          {/* Rendering x and y if comment body is unavailable (backwards compatibility) */}
          {!content.body && (
          <CommentPreview>
            {content.x}
            ,
            {' '}
            {content.y}
          </CommentPreview>
          )}
        </>
      )}

      <Footer>
        {time.format(created_at)}
        <Button size="small" style={{ fontSize: '8pt' }} onClick={(e) => { e.stopPropagation(); onToggleRead?.(id, !read); }}>
          {read && ('Mark as unread')}
          {!read && ('Mark as read')}
        </Button>
      </Footer>

    </Container>
  );
}
