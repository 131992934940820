import React, { useContext } from 'react';
import styled from 'styled-components';
// import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import {
  Link,
} from 'react-router-dom';
import Button from '@material-ui/core/Button';

import AppCtx from '../../context/AppContext';
import themes from '../../styles/themes';
import Logo from './logo.png';
import disclaimerText from '../home/disclaimer';
import ModalInformation from '../information/ModelInformation';
import Information from '../information';
import Alerts from '../alerts';

const NavbarContainer = styled.div`
  min-height: 64px;
  background-color: ${themes.mainDark};
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  position: fixed;
  width: 100%;
  /* overflow-x: auto; // Just a hack until I figure out what to do with this */
  // Right now the menu is too wide for small devices
  // Could do a pop out to show other links and buttons
`;

const IconContainer = styled.div`
  height: 24px;
  padding: 12px;
  &:hover {
    background-color: ${themes.hoverMainDark};
    border-radius: 50%;
  }
`;

// const PyrologixContainer = styled.div`
//   padding-right: 40px;
//   margin-left: auto;
//   order: 2;
//   cursor: pointer;
//   // @media (max-width: 600px) {
//   //   display: none;
//   // }
// `;

const StyledImage = styled.img`
  width: 90px;
`;

const ButtonContainer = styled.div`
  &:hover {
    background-color: ${themes.hoverMainDark};
    border-radius: ${themes.borderRadius};
  }
`;

const ProjectTitleContainer = styled.div`
  border-right: 1px solid ${themes.white};
  font-family: ${themes.fontFamilyBebas};
  font-size: ${themes.fontSizeBebas};
  color: ${themes.white};
  padding: .5rem;
  margin-right: .5rem;
  padding-right: 1.75rem;
  padding-top: 12px;
  cursor: default;
  display: flex;
`;

export default function Navbar(props) {
  const {
    selectedState,
    setSelectedState,
    setShowModal,
    setSelectedPixel,
    setClearMarkIsShowing,
    setLinkObj,
    setShowLogin,
  } = props;

  const { state } = useContext(AppCtx.Context);
  const { user } = state;

  const modalInformation = {
    header: 'Disclaimer',
    subHeader: 'wildfire.com',
    text: disclaimerText,
  };

  return (
    <NavbarContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { selectedState
      && (
        <>
          <ProjectTitleContainer>
            {selectedState.name.toUpperCase()}
            <Information setShowModal={setShowModal} modalIdentifier={selectedState.abbreviation} />
          </ProjectTitleContainer>
          <Link
            onClick={() => {
              setSelectedState(false);
              setSelectedPixel(false);
              setClearMarkIsShowing(false);
              setLinkObj({});
            }}
            to="/"
            style={{ textDecoration: 'none' }}
          >
            <IconContainer>
              <HomeIcon
                style={{ color: `${themes.white}` }}
              />
            </IconContainer>
          </Link>
        </>
      )}
        <ButtonContainer>
          <Button
            style={{ color: themes.white, fontFamily: themes.fontFamily }}
            onClick={() => setShowModal(modalInformation)}
          >
            Disclaimer
          </Button>
        </ButtonContainer>
        <ButtonContainer style={{ marginRight: '40px' }}>
          <Button
            style={{ color: themes.white, fontFamily: themes.fontFamily }}
            onClick={() => setShowModal(ModalInformation.contact)}
          >
            Contact
          </Button>
        </ButtonContainer>

        <ButtonContainer>
          <Button
            style={{ color: themes.white, fontFamily: themes.fontFamily }}
            onClick={() => setShowLogin(true)}
          >
            {user && `${user.email}`}
            {!user && 'Sign in'}
          </Button>
        </ButtonContainer>
        {selectedState && user && (<Alerts />)}

      </div>
      <div style={{
        display: 'flex', alignItems: 'center', paddingRight: '2rem',
      }}
      >

        <div>
          <a
            href="https://pyrologix.com"
            target="_blank"
            rel="noreferrer"
          >
            <StyledImage src={Logo} alt="Logo" />
          </a>
        </div>

      </div>
    </NavbarContainer>
  );
}
