import React, {
  useEffect, useState, useCallback, useContext, useRef,
} from 'react';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { IconButton, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import themes from '../../styles/themes';
import { debounce } from '../../helpers/func';
import Api from '../../api';
import AppCtx from '../../context/AppContext';
import decodeFDIST from '../../helpers/fdist';
import {
  filterValue, formatPercent, outOfWithLocale, round,
} from '../../helpers/number';
import { CopyText } from '../global';
import { CollapseMenu } from '../global/CollapseMenu';
import KV from '../global/KV';
import { decodeFvcCode } from '../../helpers/fvc';
import { decodeFvhCode } from '../../helpers/fvh';
import { decodeAttrPair } from '../../helpers/decoders';
import time from '../../helpers/time';

const Dialog = styled.div`
z-index: 9999;
`;

const InfoSection = styled.div`
    width: 30rem;
    height: 18rem;
    background-color: white;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    /* border-radius: 10px; */
    border-radius: ${themes.borderRadius};
    font-size: 10pt;
    padding: 0 0.5rem;
`;

const InfoInner = styled.div`
  height: 13rem;
  overflow-y: scroll;
`;

const Label = styled.span`
  font-size: 10pt;
  padding: 2px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;
const Value = styled.span`
  font-size: 10pt;
  padding: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-basis: 50%;
`;

const StatHead = styled.div`
  opacity: 0.7;
  font-style: italic;
  margin-left: 0.5rem;
`;

const CommentsContainer = styled.div`
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
`;

const CommentsList = styled.div`
    max-height: 13rem;
    overflow-y: scroll;
    
`;

const Comment = styled.div`
    position: relative;
    margin: 0.25rem;
    padding: 0.5rem;
    /* border-radius: 10px; */
    border-radius: ${themes.borderRadius};
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

const DelIcon = styled(DeleteIcon)`
    &:hover {
        color: red;
    }
`;

// NOTE: 2000ms is too large of a delay.
// See issue: https://github.com/pyrologix/fire-viz/issues/23
// const COMMENT_AUTOSAVE_DELAY = 2000;
const COMMENT_AUTOSAVE_DELAY = 500;

/**
 * @typedef {import('../../api/WFHazAPI/pins').Pin} Pin
 * @typedef {import('../../api/WFHazAPI/comments').Comment} Comment
 */

export default function MapDialog(props) {
  const {
    _ref, coords, sp, onClose, onSavePoint, onUnsavePoint,
  } = props;

  // console.log(sp);

  const { state, attributes } = useContext(AppCtx.Context);
  const {
    pins, projectId, user,
  } = state;

  /** @type {Comment[]} */
  const [comments, setComments] = useState([]);
  const [pointId, setPointId] = useState();
  const [pinData, setPinData] = useState();
  const [title, setTitle] = useState('Point');

  const endComments = useRef();

  const savePoint = async () => {
    const pin = await Api.Pins.post({ project_id: projectId, coords });
    setPointId(pin.id);
    onSavePoint?.(pin);
    return pin;
  };

  const unsavePoint = async () => {
    const pId = pointId;
    await Api.Pins.delete(pId);

    setPointId(undefined);
    setComments([]);
    onUnsavePoint?.(pId);
  };

  const fetchComments = async (pId) => {
    /** @type {Array<Comment>} */
    const comms = await Api.Comments.get({ pin_id: pId });
    // console.log('Comments: ', comms);
    return comms;
  };

  const refreshComments = async () => {
    // console.log('refreshing comments', pointId);
    const comms = await fetchComments(pointId);
    setComments(comms);
  };

  const useLoadPoint = async () => {
    const x = coords[0];
    const y = coords[1];

    /** @type {Pin} */
    const savedPin = pins.find((p) => (p.x === x && p.y === y));
    if (!savedPin) {
      setPointId(undefined);
      setComments([]);
      return;
    }

    const pId = savedPin.id;
    const comms = await fetchComments(pId);

    setPointId(pId);
    setComments(comms);
  };

  const addComment = async () => {
    let pId = pointId;
    // If the pin hasn't been saved yet, we do that first
    if (!pId) {
      const newPin = await savePoint();
      pId = newPin.id;
    }

    const newComm = await Api.Comments.post({ pin_id: pId, body: '' });
    // console.log('new comment: ', newComm);

    const temp = [...comments];
    temp.push(newComm);
    setComments(temp);

    endComments.current.scrollIntoView();
  };

  const delComment = async (cId) => {
    const temp = comments.filter((n) => n.id !== cId);
    setComments(temp);

    await Api.Comments.delete({ id: cId, pin_id: pointId });
  };

  const updateComment = async (cId, txt) => {
    // update the comment locally (better ux?)
    const temp = comments.map((n) => {
      if (n.id === cId) {
        return { ...n, body: `${txt}` };
      }
      return { ...n };
    });
    setComments(temp);

    // update the comment on the backend
    await Api.Comments.put({ id: cId, pin_id: pointId, body: txt });
  };

  const updateCommentDeb = useCallback(debounce(updateComment, COMMENT_AUTOSAVE_DELAY), [comments]);

  const userColor = (dname) => {
    // eslint-disable-next-line no-bitwise
    const hash = [...dname].reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    return `hsl(${hash % 360}, 95%, 35%)`;
  };

  const getAllData = async () => {
    const d = {
      evt: { code: '', display: '' },
      bps: { code: '', display: '' },
      fdist: { code: '', display: '' },
      fbsum: {},
    };
    const allResps = await Promise.all([
      Api.BiophyiscalSettings.get({ id: sp.bps }).catch(() => ({})),
      Api.Evts.get({ id: sp.fvt }).catch(() => ({})),
      Api.FbSums.get({ id: sp.rule_id }).catch(() => ({})),
    ]);

    if (allResps[0].name) {
      d.bps = { code: allResps[0].code, display: allResps[0].name };
    }

    if (allResps[1].name) {
      d.evt = { code: allResps[1].code, display: allResps[1].name };
    }

    if (allResps[2].id) {
      d.fbsum = { ...allResps[2] };
    }

    /**
     * Get FDIST string representation of code
     * https://landfire.gov/DataDictionary/LF200/LF2016Remap_FDist.pdf
     *
     * Can be zero
     *
     */
    if (sp.fdist !== undefined) {
      const fdistStr = decodeFDIST(sp.fdist);
      d.fdist.code = sp.fdist;
      d.fdist.display = fdistStr;
      // console.log(`FDIST: ${fdistStr}`);
    }

    setPinData(d);
  };

  const buildTitle = () => {
    let t = 'Point';
    Object.keys(attributes).forEach((k) => {
      const attr = attributes[k];
      if (attr.selected === true) {
        // let val = sp?.[attr.iName] || '';

        let val = decodeAttrPair(attr.iName, sp?.[attr.iName]) || 'Unknown';

        // HACK (this isn't necessary if we fix number.numeric)
        if (attr.iName === 'fvt') {
          val = pinData?.evt?.code || 'Unknown';
        }

        if (attr.iName === 'bps') {
          val = pinData?.bps?.code || 'Unknown';
        }

        t = `${attr.name}: ${val}`;
      }
    });
    setTitle(t);
  };

  useEffect(() => {
    setComments([]);
    if (coords[0] !== 0 && coords[1] !== 0) {
      // whenever coords change, we check to see if there
      // is any data that we need to load for a given point
      useLoadPoint();
    }
  }, [coords]);

  useEffect(() => {
    if (sp) {
      getAllData();
    }
  }, [coords]);

  useEffect(() => {
    // Whenever attributes and or coords change we build the
    // dialog's title
    if (attributes) {
      buildTitle();
    }
  }, [attributes, coords]);

  // console.log('sp', sp);

  return (
    <Dialog ref={_ref} style={{ display: (!user ? 'none' : '') }}>
      <InfoSection style={{ fontFamily: themes.fontFamily }}>
        <div style={{ fontSize: '9pt', position: 'relative', paddingTop: '10px' }}>

          <div
            title={title}
            style={{
              fontSize: '12pt',
              fontFamily: themes.fontFamilyBold,
              marginLeft: '0.25rem',
              letterSpacing: '1px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '28rem',
              cursor: 'pointer',
            }}
          >
            {title}
          </div>
          <ClearIcon
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              top: 4,
              right: 0,
              position: 'absolute',
            }}
            onClick={() => { onClose?.(); }}
          />

        </div>

        <div style={{
          height: '2px', width: '100%', backgroundColor: '#ebeced', borderRadius: '50px',
        }}
        />

        <InfoInner>

          {(sp && (
          <div style={{ marginTop: '0.5rem' }}>
            <CollapseMenu hlabel="Canopy/Surface Summary">
              <KV.List
                style={{ paddingLeft: '0.5rem', paddingTop: '0.2rem' }}
                data={[
                  { key: 'Existing Veg. Type', value: decodeAttrPair('evt', pinData?.evt?.display, sp.fvt) },
                  { key: 'BPS', value: decodeAttrPair('bps', pinData?.bps?.display, sp.bps) },
                  { key: 'FM40', value: decodeAttrPair('fm40', sp.fm40) },
                  { key: 'Canopy Base Height', value: `${decodeAttrPair('cbh', sp.cbh)}` },
                  { key: 'Canopy Cover', value: decodeAttrPair('cc', sp.cc) },
                  { key: 'Canopy Bulk Density', value: decodeAttrPair('cbd', sp.cbd) },
                  { key: 'Canopy Height', value: decodeAttrPair('ch', sp.ch) },
                  { key: 'Fuel Disturbance', value: decodeAttrPair('fdist', sp.fdist) },
                  { key: 'EVC', value: decodeAttrPair('evc', sp.evc) },
                  { key: 'EVH', value: decodeAttrPair('evh', sp.evh) },
                  { key: 'Slope', value: decodeAttrPair('slp', sp.slp) },
                  { key: 'Aspect', value: decodeAttrPair('asp', sp.asp) },
                ]}
              />
            </CollapseMenu>
            <CollapseMenu hlabel="Rule Summary" hstyle={{ marginTop: '5px' }} startCollapsed>
              <KV.List
                style={{ paddingLeft: '0.5rem', paddingTop: '0.2rem' }}
                data={[
                  { key: 'Canopy Guide', value: `${decodeAttrPair('cg', pinData?.fbsum?.canopy_guide)}` },
                  { key: 'Cover Range', value: `${decodeFvcCode(pinData?.fbsum.cover_low, pinData?.fbsum.cover_high)}` },
                  { key: 'Height Range', value: `${decodeFvhCode(pinData?.fbsum.height_low, pinData?.fbsum.height_high)}` },
                  { key: '% of total EVT', value: `${formatPercent(pinData?.fbsum?.pixels, pinData?.fbsum?.evt_pixels)}` },
                  { key: 'Pixels/EVT Pixels', value: `${outOfWithLocale(pinData?.fbsum?.pixels, pinData?.fbsum?.evt_pixels)}` },
                  { key: 'Rule ID', value: `${pinData?.fbsum?.rule_id || 'Unknown'}` },
                ]}
              />
            </CollapseMenu>
            <CollapseMenu hlabel="National Burn Probability" hstyle={{ marginTop: '5px' }} startCollapsed>
              <KV.List
                style={{ paddingLeft: '0.5rem', paddingTop: '0.2rem' }}
                data={[
                  { key: 'Burn Probability', value: `${decodeAttrPair('bp', sp.bp, 1)}` },
                ]}
              />
            </CollapseMenu>
            <CollapseMenu hlabel="Fire Behavior Statistics" hstyle={{ marginTop: '5px' }} startCollapsed>
              <>
                <div style={{ display: 'flex' }}>
                  <Label style={{ flexBasis: '35%' }} />
                  <Label style={{ flexBasis: '20%' }}>15 mi/hr</Label>
                  <Label style={{ flexBasis: '20%' }}>WildEST</Label>
                  <Label style={{ flexBasis: '20%' }}>Rule Mean</Label>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>FlameLength (ft.)</StatHead>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.fl, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.wfl, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, pinData?.fbsum?.mean_fl, round)}</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>Rate of Spread (ch/hr)</StatHead>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.ros, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.wros, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, pinData?.fbsum?.mean_ros, round)}</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>Torching Index (mi/hr)</StatHead>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, sp.ti, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, pinData?.fbsum?.mean_ti, round)}</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>Crowning Index (mi/hr)</StatHead>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, sp.ci, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, pinData?.fbsum?.mean_ci, round)}</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>Wind Adjustment Factor</StatHead>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.waf, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, pinData?.fbsum?.mean_waf, round)}</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>FTP - Active</StatHead>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_active, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>FTP - Passive</StatHead>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_passive, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>FTP - Underburn</StatHead>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_underburn, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatHead style={{ flexBasis: '35%' }}>FTP - Surface</StatHead>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                  <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_surface, round)}</Value>
                  <Value style={{ flexBasis: '20%' }}>-</Value>
                </div>
              </>
            </CollapseMenu>
          </div>
          ))}
        </InfoInner>
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem',
        }}
        >
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                if (pointId) unsavePoint(); else savePoint();
              }}
              style={{
                backgroundColor: themes.secondaryDark, color: themes.white, fontSize: '8pt',
              }}
              size="small"
            >
              {pointId && 'Unsave Point'}
              {!pointId && 'Save Point'}
            </Button>
            {/* <Button
            onClick={() => {
              getAllData();
            }}
            style={{
              backgroundColor: themes.secondaryDark,
              color: themes.white, marginBottom: '0.25rem', fontSize: '10px',
            }}
            size="small"
          >
            Get all data
          </Button> */}
            <div style={{
              display: 'flex', alignItems: 'center', marginLeft: '0.4rem',
            }}
            >
              <Button
                onClick={addComment}
                style={{
                  backgroundColor: themes.secondaryDark, color: themes.white, fontSize: '8pt',
                }}
                size="small"
              >
                Add Comment
              </Button>
              <IconButton onClick={refreshComments} style={{ padding: '0.2rem', marginLeft: '0.2rem' }}>
                <RefreshIcon style={{ fontSize: '13pt' }} />
              </IconButton>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <CopyText text={`${round(coords[0])}, ${round(coords[1])}`} />
            <CopyText style={{ marginLeft: '0.3rem' }} text="EPSG:3857" />
          </div>
        </div>
      </InfoSection>
      <CommentsContainer>
        <CommentsList>
          {comments.map((c) => (
            <Comment key={c.id}>
              {(c.user_id === user.id) && (
                <span style={{
                  fontSize: '10pt',
                  fontFamily: themes.fontFamily,
                  fontStyle: 'italic',
                  color: 'steelblue',
                  // opacity: 0.8,
                }}
                >
                  You
                </span>
              )}
              {(c.user_id !== user.id) && (
                <span style={{
                  fontSize: '10pt',
                  fontFamily: themes.fontFamily,
                  fontStyle: 'italic',
                  // opacity: 0.8,
                  // color: 'coral',
                  color: userColor(c.display_name),
                }}
                >
                  {c.display_name}
                </span>
              )}
              {(c.type === 'admin' && c.user_id !== user.id) && (
                <span style={{
                  marginLeft: '0.2rem',
                  fontSize: '9pt',
                  fontFamily: themes.fontFamily,
                  fontStyle: 'italic',
                  // color: 'coral',
                  color: userColor(c.display_name),
                  // opacity: 0.6,
                }}
                >
                  (Admin)
                </span>
              )}
              <span style={{
                fontSize: '9pt',
                fontFamily: themes.fontFamily,
                fontStyle: 'italic',
                opacity: 0.3,
                marginLeft: '0.5rem',
              }}
              >
                {time.format(c.created_at)}
              </span>

              <TextField
                disabled={(c.user_id !== user.id)}
                multiline
                fullWidth
                defaultValue={c.body}
                InputProps={{ disableUnderline: true, style: { fontSize: '10pt' } }}
                onChange={(e) => updateCommentDeb(c.id, e.target.value)}
              />
              {(c.user_id === user.id) && (
              <IconButton
                onClick={() => delComment(c.id)}
                style={{
                  padding: '0.25rem',
                  cursor: 'pointer',
                  top: 0,
                  right: 1,
                  position: 'absolute',
                }}
              >
                <DelIcon
                  style={{ fontSize: '18px' }}
                />
              </IconButton>
              )}

            </Comment>
          ))}
          <div ref={endComments} />
        </CommentsList>
      </CommentsContainer>
    </Dialog>
  );
}
