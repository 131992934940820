const swrapPercentiles = [
  // { x: 0, y: 3.57e-16 },
  // { x: 1, y: 2.75e-7 },
  // { x: 2, y: 0.00000281 },
  // { x: 3, y: 0.00000685 },
  // { x: 4, y: 0.0000098 },
  // { x: 5, y: 0.0000114 },
  // { x: 6, y: 0.000014 },
  // { x: 7, y: 0.0000168 },
  // { x: 8, y: 0.0000197 },
  // { x: 9, y: 0.0000227 },
  { x: 10, y: 0.0000257 },
  { x: 11, y: 0.0000287 },
  { x: 12, y: 0.0000319 },
  { x: 13, y: 0.0000353 },
  { x: 14, y: 0.0000388 },
  { x: 15, y: 0.0000426 },
  { x: 16, y: 0.0000465 },
  { x: 17, y: 0.0000508 },
  { x: 18, y: 0.0000553 },
  { x: 19, y: 0.0000603 },
  { x: 20, y: 0.0000656 },
  { x: 21, y: 0.0000713 },
  { x: 22, y: 0.0000776 },
  { x: 23, y: 0.0000844 },
  { x: 24, y: 0.0000916 },
  { x: 25, y: 0.0000992 },
  { x: 26, y: 0.000107193 },
  { x: 27, y: 0.000115716 },
  { x: 28, y: 0.00012466 },
  { x: 29, y: 0.000133985 },
  { x: 30, y: 0.000143696 },
  { x: 31, y: 0.000153767 },
  { x: 32, y: 0.000164194 },
  { x: 33, y: 0.00017503 },
  { x: 34, y: 0.000186258 },
  { x: 35, y: 0.000197841 },
  { x: 36, y: 0.000209852 },
  { x: 37, y: 0.000222367 },
  { x: 38, y: 0.000235382 },
  { x: 39, y: 0.000248892 },
  { x: 40, y: 0.000262862 },
  { x: 41, y: 0.000277336 },
  { x: 42, y: 0.000292365 },
  { x: 43, y: 0.000307963 },
  { x: 44, y: 0.000324234 },
  { x: 45, y: 0.00034123 },
  { x: 46, y: 0.000358973 },
  { x: 47, y: 0.000377562 },
  { x: 48, y: 0.000397087 },
  { x: 49, y: 0.000417688 },
  { x: 50, y: 0.000439401 },
  { x: 51, y: 0.000462283 },
  { x: 52, y: 0.000486562 },
  { x: 53, y: 0.000512209 },
  { x: 54, y: 0.00053938 },
  { x: 55, y: 0.000568267 },
  { x: 56, y: 0.000598824 },
  { x: 57, y: 0.000631187 },
  { x: 58, y: 0.00066539 },
  { x: 59, y: 0.00070186 },
  { x: 60, y: 0.000740605 },
  { x: 61, y: 0.000781747 },
  { x: 62, y: 0.000825497 },
  { x: 63, y: 0.000871777 },
  { x: 64, y: 0.000920119 },
  { x: 65, y: 0.000970407 },
  { x: 66, y: 0.001022767 },
  { x: 67, y: 0.001077814 },
  { x: 68, y: 0.001136142 },
  { x: 69, y: 0.001198289 },
  { x: 70, y: 0.00126415 },
  { x: 71, y: 0.001334024 },
  { x: 72, y: 0.001409665 },
  { x: 73, y: 0.001491799 },
  { x: 74, y: 0.001581214 },
  { x: 75, y: 0.001677752 },
  { x: 76, y: 0.001781472 },
  { x: 77, y: 0.001893243 },
  { x: 78, y: 0.002014109 },
  { x: 79, y: 0.002146758 },
  { x: 80, y: 0.002292519 },
  { x: 81, y: 0.002452077 },
  { x: 82, y: 0.002627823 },
  { x: 83, y: 0.002820246 },
  { x: 84, y: 0.003030362 },
  { x: 85, y: 0.00326033 },
  { x: 86, y: 0.003513079 },
  { x: 87, y: 0.003791307 },
  { x: 88, y: 0.004094153 },
  { x: 89, y: 0.004428645 },
  { x: 90, y: 0.00481109 },
  { x: 91, y: 0.005250472 },
  { x: 92, y: 0.005761589 },
  { x: 93, y: 0.006339858 },
  { x: 94, y: 0.007014147 },
  { x: 95, y: 0.007841594 },
  { x: 96, y: 0.008887676 },
  { x: 97, y: 0.010266332 },
  { x: 98, y: 0.012175651 },
  { x: 99, y: 0.015817208 },
  { x: 100, y: 0.121365398 },
];

export default swrapPercentiles;
