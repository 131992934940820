import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
    height: 2px; 
    width: 100%;
    background-color: #ebeced;
    border-radius: 50px;
`;

export function Divider(props) {
  const { style } = props;

  return (
    <Bar style={style} />
  );
}

export default Divider;
