import { useEffect, useRef } from 'react';

/**
 * Simple function debouncer - creates a debounced function that delays
 * invoking func until after delay milliseconds have elapsed since
 * the last time the debounced function was invoked
 * @param {Function} func - the function to debounce
 * @param {Number} delay - the delay between function invocations (defaults to 1000)
 * @returns {Function}
 *
 * @example
 * ```
 * const myFunction = (params) => {
 *  console.log('debounce me!');
 * };
 *
 * const myDebFunction = debounce(myFunction, 1000);
 * myDebFunction(someParams);
 * ```
 */
export const debounce = (func, delay = 1000) => {
  let timer;
  // eslint-disable-next-line func-names
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export function usePolling(callback, delay) {
  // eslint-disable-next-line no-underscore-dangle
  const _cb = useRef();

  useEffect(() => {
    _cb.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function caller() {
      _cb.current();
    }

    if (delay !== null) {
      const interval = setInterval(caller, delay);
      return () => {
        clearInterval(interval);
      };
    }
  }, [callback, delay]);
}

export default {
  debounce,
};
