import React from 'react';

const ModalInformation = {
  bPSplash: {
    header: 'Annual Burn Probability',
    subHeader: 'Example',
    text: 'Explanatory text describing methods with a download link',
    isPDF: true,
    startPage: 1,
    pDFNumPages: 4,
    startScale: 2.0,
    dataURL: 'https://data.wildfirehazard.com/info-wildfirehazard.pdf',
  },
  ca: {
    header: 'Contemporary Wildfire Hazard Maps For California',
    subHeader: 'Example',
    text: 'Explanatory text describing methods with a download link',
    isPDF: true,
    startPage: 1,
    pDFNumPages: 8,
    startScale: 2.0,
    dataURL: 'https://data.wildfirehazard.com/info-wildfirehazard-ca.pdf',
  },
  mt: {
    header: 'Contemporary Wildfire Hazard Maps For Montana',
    subHeader: 'Example',
    text: 'Explanatory text describing methods with a download link',
    isPDF: true,
    startPage: 1,
    pDFNumPages: 5,
    startScale: 2.0,
    dataURL: 'https://data.wildfirehazard.com/Info-wildfirehazard-mt.pdf',
  },
  contact: {
    header: 'Contact Us',
    subHeader: 'Subheader',
    text: (
      <span>
        Feel free to contact us at:
        {' '}
        <a href="mailto:wildfirehazard@pyrologix.com">
          wildfirehazard@pyrologix.com
        </a>
      </span>),
  },
};

export default ModalInformation;
