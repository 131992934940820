import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import themes from '../../styles/themes';
import { Icon } from '../global/Icon';
import { Divider } from '../global/Divider';
import Api from '../../api';
import { usePolling } from '../../helpers/func';
import AppContext from '../../context/AppContext';
import { CollapseMenu } from '../global/CollapseMenu';

import Alert from './Alert';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const AlertBox = styled.div`
  position: absolute;
  top: 2.6rem;
  left: -15rem;
  width: 20rem;
  height: 15rem;
  background-color: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-bottom-left-radius: ${themes.borderRadius};
  border-bottom-right-radius: ${themes.borderRadius};
`;

const AlertNum = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 0 0.25rem;
  font-size: 9pt;
  border-radius: 50px;
  background-color: red;
  color: white;
  text-align: center;
  font-family: ${themes.fontFamily};
`;

const AlertList = styled.div`
  padding: 0.25rem 0.5rem;
  height: 12rem;
  overflow-y: scroll;
`;

const POLL_DELAY = 15000;

export default function Alerts() {
  const { mapRef } = useContext(AppContext.Context);
  const [showAlerts, setShowAlerts] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [unread, setUnread] = useState(0);

  const fetchAlerts = async () => {
    const temp = [];
    const newAlerts = await Api.Alerts.get();
    let numUnread = 0;
    newAlerts.forEach((al) => {
      if (al.read === false) numUnread += 1;
      temp.push(al);
    });

    temp.sort((lhs, rhs) => new Date(rhs.created_at) - new Date(lhs.created_at));

    setUnread(numUnread);
    setAlerts(temp);
  };

  const updateRead = async (alertId, isRead) => {
    await Api.Alerts.put({ id: alertId, read: isRead });
    fetchAlerts();
  };

  const goToCoords = (x, y) => {
    mapRef.current.toCoords(x, y);
    setShowAlerts(false);
  };

  const handleAlertClick = async (a) => {
    if (a.content.type === 'new_comment') {
      // NOTE: loading all pins whenever alert is clicked
      // this is pretty naive and at most times unnecessary.
      // But this works for now until we have socket integration.
      await mapRef.current.loadPins();
      await updateRead(a.id, true);
      goToCoords(a.content.x, a.content.y);
      setShowAlerts(false);

      // mapRef.current.loadPins();
    }
  };

  useEffect(() => {
    // Fetch alerts on mount
    fetchAlerts();
  }, []);

  // Poll the backend every "n" milliseconds
  // for the alerts
  usePolling(fetchAlerts, POLL_DELAY);

  return (
    <Container>
      <Icon
        onClick={() => setShowAlerts(!showAlerts)}
        icon="messages"
        style={{ color: 'white', fontSize: '14pt', marginLeft: '2rem' }}
      />
      {unread > 0 && (
        <AlertNum>{unread}</AlertNum>
      )}
      {showAlerts && (
        <AlertBox>
          <div style={{ fontSize: '12pt', padding: '0.5rem', fontFamily: themes.fontFamily }}>Notifications</div>
          <Divider />
          {(alerts.length === 0) && (
            <div style={{
              fontSize: '10pt', padding: '0.5rem', fontFamily: themes.fontFamily, textAlign: 'center', opacity: 0.5, fontStyle: 'italic',
            }}
            >
              Empty
            </div>
          )}

            {/* List of alerts */}
          {(alerts.length > 0) && (
            <AlertList>
              {/* Unread alerts */}
              <CollapseMenu hlabel="Unread" hstyle={{ fontSize: '10pt' }}>
                {alerts.filter((a) => !a.read).map((a) => (
                  <Alert key={a.id} data={a} onToggleRead={updateRead} onClick={handleAlertClick} />
                ))}
              </CollapseMenu>

              {/* Read alerts */}
              <CollapseMenu hlabel="Read" startCollapsed hstyle={{ fontSize: '10pt', marginTop: '0.25rem' }}>
                {alerts.filter((a) => a.read).map((a) => (
                  <Alert key={a.id} data={a} onToggleRead={updateRead} onClick={handleAlertClick} />
                ))}
              </CollapseMenu>
            </AlertList>
          )}

        </AlertBox>
      )}
    </Container>
  );
}
