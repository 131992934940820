import { DateTime } from 'luxon';

const format = (d, useRelative = true) => {
  const dt = DateTime.fromISO(d);
  if (useRelative === false) return dt.toLocaleString(DateTime.DATETIME_SHORT);

  const now = DateTime.now();
  const diff = now.diff(dt, 'hours');
  if (diff.hours > 24) return dt.toLocaleString(DateTime.DATETIME_SHORT);

  // if the diff is less than 24 hours then we will return
  // a relative time
  return dt.toRelative();
};

export default {
  format,
};
